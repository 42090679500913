<template>
  <div class="ai-studio-container">
    <div class="ai-studio-center-wrap">
      <div id="studio-page-id" class="studio-page">
        <transition name="fade">
          <div v-if="show" :style= "`position: inherit;`">
            <div id="view2" :style= "`background: url(${require('@/assets/images/main_bg2.jpg')});background-repeat: no-repeat; background-size: 100% 100%; z-index: 100; height:1000px ; position: inherit;`">
              <div class="page_title" style="padding: 30px;">
                <img src="@/assets/images/top_logo.jpg">
              </div>

              <div class="page_contents d-flex justify-content-center" style="display: flex; align: center">
                  <div class="box_margin_x" style="width: 384px; height: 663px; margin: 20px; border-radius: 12px; overflow: hidden; position: relative;">
                    <div :style="`background: url(${require('@/assets/images/monthly1.jpg')}); width: 374px; height: 320px; margin:5px; padding: 10px; border-radius: 12px; box-shadow: 1px 1px 7px 0px #333; `">
                    </div>
                    <div :style="`background: url(${require('@/assets/images/event2.jpg')}); width: 374px; height: 320px; margin:5px; padding: 10px; margin-top: 30px; border-radius: 12px; box-shadow: 1px 1px 7px 0px #333;`">
                    </div>
                  </div>
                  <div class="box box_margin_x" :style="`width: 384px; height: 663px; margin: 20px; overflow: hidden; border-radius: 12px; box-shadow: 1px 1px 7px 0px #333; position: relative; background: url(${require('@/assets/images/event_main_1.jpg')});`">
                  </div>
                  <div style="width: 384px; height: 663px; margin: 20px;  border-radius: 12px;">
                    <div class="text_box box bg2 size3 box_margin_y" style="overflow: hidden; position: relative; width: 374px; height:100px; margin-left: 5px; margin-bottom: 5px; border-radius: 12px; box-shadow: 1px 1px 7px 0px #333; background-color: #0f77c0;">
                      <ul style="margin-top: 15px;">
                      <li class="font_size20" style="font-size: 20pt; list-style:none;">관람시간 <span class="point_text" style="color: #fff200;">09:00~18:00</span> 시</li>
                      <li class="font_size12 font_family1" style="font-size: 12pt; list-style:none;">(<span class="font_family2">휴관일</span> : 매주 월요일, 1월 1일, 추석·설날 당일)</li>
                      </ul>
                    </div>
                    <div :style="`width: 374px; height: 310px; border-radius: 12px; box-shadow: 1px 1px 7px 0px #333; margin:5px; margin-top: 20px; margin-bottom: 20px; background-repeat: none; background: url(${require('@/assets/images/event1.jpg')});`">
                    </div>
                    <div style="width: 384px; height: 210px; border-radius: 12px; box-shadow: 1px 1px 7px 0px #333; overflow:hidden">
                      <div id="ww_52402f0550f2c" v='1.3' loc='id' a='{"t":"horizontal","lang":"ko","sl_lpl":1,"ids":["wl7444"],"font":"Arial","sl_ics":"one","sl_sot":"celsius","cl_bkg":"image","cl_font":"#FFFFFF","cl_cloud":"#FFFFFF","cl_persp":"#81D4FA","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722"}'>More forecasts: <a href="https://sharpweather.com/ko/seoul/" id="ww_52402f0550f2c_u" target="_blank">날씨 시간별 서울</a></div>
                    </div>
                    <script type="application/javascript" defer src="https://app2.weatherwidget.org/js/?id=ww_52402f0550f2c"></script>
                  </div>
              </div>
            </div>
          </div>
        </transition>

        <div>
          <div v-for="(item,index) in selectedComponentList" :key="item.componentId+'_'+index" >
            <div>
              <div v-if="item.type === 'video'" class="component video-component" :component_id="item.componentId" :style="getStyle (item)">
                <video :src="getSrc(item)"></video>
              </div>
              <div v-if="item.type === 'image'" class="component image-component" :component_id="item.componentId" :style="getStyle (item)">
                <img :src="getSrc(item)"/>
              </div>
              <div v-if="item.type === 'quiz'" class="component quiz-component" :component_id="item.componentId" :style="getQuizStyle(item)">
                <div class="quiz-question"><span class="question_num">Q. </span> <span class="question_data">{{item.question}}</span></div>
                <div class="quiz_answer"><span class="question_data">정답은 </span><span class="question_num quiz_ox">{{item.answer}}</span><span class="question_data"> 입니다.</span></div>
              </div>
              <div v-if="item.type === 'hiddenCatch'" class="component hiddenCatch-component" :component_id="item.componentId" :style="getHiddenCatchStyle (item)">
                <div class="catch-div">
                  <img :src="getSrc(item, 0)"/>
                  <div class="hidden-circle" v-for="(v,i) in hiddenCatchCircles" :key="'circle2_'+i" :style="getHiddenCatchCirCleStyle(v, item)">
                    <img :src="require('@/assets/images/select.png')"/>
                  </div>
                  <div class="hidden-circle" v-for="(v,i) in item.circles" :key="'circle_'+i" :style="getHiddenCatchCirCleStyle(v, item)" v-show="isComplete">
                    <img :src="require('@/assets/images/circle.png')"/>
                  </div>
                </div>
                <div class="catch-div">
                  <img :src="getSrc(item, 1)"/>
                  <div class="hidden-circle" v-for="(v,i) in hiddenCatchCircles" :key="'circle2_'+i" :style="getHiddenCatchCirCleStyle(v, item)">
                    <img :src="require('@/assets/images/select.png')"/>
                  </div>
                  <div class="hidden-circle" v-for="(v,i) in item.circles" :key="'circle_'+i" :style="getHiddenCatchCirCleStyle(v, item)" v-show="isComplete">
                    <img :src="require('@/assets/images/circle.png')"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <transition name="fade">
        <div v-if="show_suggest" :style= "`position: inherit; display: flex; align: center; justify-content: center;`">
          <div class="suggestion-text-box-left">
            <p class="suggestion-text-label" >독립관 안내</p>
            <p class="suggestion-text-label" >이달의 독립운동가</p>
          </div>
          <div class="suggestion-text-box-right">
            <p class="suggestion-text-label" >주요 이벤트</p>
            <p class="suggestion-text-label" >주변 관람지 안내</p>
          </div>
        </div>
        </transition>
        <div class="translate-text" v-show="translatedText">
          <span id="subject_text" class="translate-text-label">{{translatedText}}</span>
        </div>
        <div id="character" :style="getCharacterStyle()">
        </div>
        <div style="position:absolute; bottom:0; right:0;"><canvas id="canvas"></canvas></div>
        <div id="label-container" style="position:absolute; top:50px; right:50px; font-size: 24px;"></div>
        <div :style= "`position: absolute;top: 0px;left: 40px;`">
          <p><button id="record" class="btn" :class="{'active' : recording}" :style="`width: 100px;height: 80px;background: #ddd;border-radius: 20px;margin: 20px; opacity: 0`"><img :src="require('@/assets/images/microphone.png')" class="microphone" :style="`width: 50px;height: 50px;`"/></button></p>
          <!-- <div id="lang_btn_list" class="ai-curator-bottom-language-button">
            <button class="btn bottom-language" :class="{'btn-danger' : lang === 'ko-KR', 'btn-primary' : lang !== 'ko-KR'}" @click="changeLang('ko-KR')"><img src="../components/img/korea.png" style="width: 24px; height: 24px; float: left; margin-left: 5px;"><p style="margin-bottom: 0; float: left; padding-left: 10px;">한국어</p></button>
            <button class="btn bottom-language" :class="{'btn-danger' : lang === 'en-US', 'btn-primary' : lang !== 'en-US'}" @click="changeLang('en-US')"><img src="../components/img/england.png" style="width: 24px; height: 24px; float: left; margin-left: 5px;"><p style="margin-bottom: 0; float: left; padding-left: 10px; font-size : 13px;">ENGLISH</p></button>
            <button class="btn bottom-language" :class="{'btn-danger' : lang === 'zh-CN', 'btn-primary' : lang !== 'zh-CN'}" @click="changeLang('zh-CN')"><img src="../components/img/china.png" style="width: 24px; height: 24px; float: left; margin-left: 5px;"><p style="margin-bottom: 0; float: left; padding-left: 10px;">中國語</p></button>
            <button class="btn bottom-language" :class="{'btn-danger' : lang === 'ja-JP', 'btn-primary' : lang !== 'ja-JP'}" @click="changeLang('ja-JP')"><img src="../components/img/japan.png" style="width: 24px; height: 24px; float: left; margin-left: 5px;"><p style="margin-bottom: 0; float: left; padding-left: 10px;">日本語</p></button>
            <button class="btn bottom-language" :class="{'btn-danger' : lang === 'th-TH', 'btn-primary' : lang !== 'th-TH'}" @click="changeLang('th-TH')"><img src="../components/img/tai.png" style="width: 24px; height: 24px; float: left; margin-left: 5px;"><p style="margin-bottom: 0; float: left; padding-left: 10px;font-size : 14px;">ภาษาไทย</p></button>
            <button class="btn bottom-language" :class="{'btn-danger' : lang === 'id-ID', 'btn-primary' : lang !== 'id-ID'}" @click="changeLang('id-ID')"><img src="../components/img/inni.png" style="width: 24px; height: 24px; float: left; margin-left: 5px;"><p style="margin-bottom: 0; float: left; padding-left: 10px; font-size : 12px;"> بهاس إندونيسيا</p></button>
            <button class="btn bottom-language" :class="{'btn-danger' : lang === 'ru-RU', 'btn-primary' : lang !== 'ru-RU'}" @click="changeLang('ru-RU')"><img src="../components/img/russ.png" style="width: 24px; height: 24px; float: left; margin-left: 5px;"><p style="margin-bottom: 0; float: left; padding-left: 10px;font-size : 14px;">ру́сский</p></button>
            <button class="btn bottom-language" :class="{'btn-danger' : lang === 'fr-FR', 'btn-primary' : lang !== 'fr-FR'}" @click="changeLang('fr-FR')"><img src="../components/img/fran.png" style="width: 24px; height: 24px; float: left; margin-left: 5px;"><p style="margin-bottom: 0; float: left; padding-left: 10px;font-size : 14px;">français</p></button>
          </div> -->
        </div>
      </div>
      <div id="audio-clip" v-if="audioShow">
        <audio id="output" ref="output" :src="audioSrc"></audio>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @import url("../../assets/css/ai-qurator-front.css");
  // @import url("../../assets/css/ai-qurator-bottom.css");
  </style>
<script>
import * as Three from 'three'
import GUI from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { core, FIREBASECONFIG } from '../../config/pluginInit'
import { initializeApp } from 'firebase/app'
import { getDatabase, ref, onValue } from 'firebase/database'
import _ from 'lodash'
import $ from 'jquery'
import _axios from '../services/front'
import * as tmPose from '@teachablemachine/pose'
// import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity'

export default {
  name: 'AIStudio',
  components: {

  },
  data () {
    return {
      app: null,
      show: true,
      show_suggest: false,
      recording: false,
      issCount: 0,

      settings: {
        replaceUrl: 'http://127.0.0.1:8000',
        modelURL: '/media/aimodel/model.json',
        metadataURL: '/media/aimodel/metadata.json',
        ratio: 20 / 17
      },

      qurator: {
        clock: null,
        mixer: null,
        actions: null,
        activeAction: null,
        previousAction: null,
        camera: null,
        scene: null,
        renderer: null,
        face: null,
        api: { state: 'idle' }
      },

      lang: 'ko-KR',
      left: 0,
      top: 0,
      width: 450,
      height: 750,
      showCharacter: true,
      projectCharacter: { model: 'finishment.glb', body: 'GEO_Head.004' },
      characterList: [
        'finishment.glb'
      ],
      character: 0,
      characterCss: {
        left: 650,
        top: 148
      },
      serial: 'A1',
      projectId: 1,
      contentsList: [],
      selectedComponentList: [],
      selectedContents: null,
      components: [],
      fileList: [],
      fileGroupList: [],
      animationList: [],
      checkFirst: true,
      audioSrc: '/media/output.mp3?r=' + Math.random(),
      waveInterval: null,
      faceInterval: null,
      faceCount: 0,
      countArrow: true,
      aiModel: null,
      webcam: null,
      ctx: null,
      labelContainer: null,
      maxPredictions: null,
      reciveAnswer: '',
      reciveHiddenCatchComplete: '',
      isComplete: false,
      hiddenCatchCircles: [],
      isFirst: true,
      isHiddenCatchSelectFirst: true,
      isHiddenCatchCompleteFirst: true,
      isPlayedObjects: [],
      defaultCharacterStyle: {},
      isVideoPlay: false,
      interval: null,
      states: ['idle', 'walk', 'talk'],
      emotes: ['point', 'attention', 'suggestion2', 'suggestion', 'greet', 'handsup'],
      inCheck: false,
      isClear: false,
      isAnimated: false,
      audioShow: true,
      timeout: {
        main: null,
        quiz: null,
        text: null,
        video: null
      },
      translatedText: '',
      selectedAnimationList: [],
      cnt: 0
    }
  },
  async mounted () {
    core.index()
    await this.studioInit()
    await this.firebaseInit()
    await this.init()
    this.animate()
    this.motionInit()
    // this.connect()
    setTimeout(() => {
      this.micropone()
      this.connect()
      $('#lang_btn_list').fadeIn('slow')
    }, 500)

    // this.selectContents(5)
  },
  created () { // html 로딩되기전에 실행됨

  },
  updated () {
    if (!this.audioShow) {
      this.audioShow = true
      this.faceCount = 0
      // this.qurator.face.morphTargetInfluences[1] = this.faceCount
      if (this.faceInterval) {
        clearInterval(this.faceInterval)
      }
    }

    if (this.isClear) {
      this.isClear = false
    }
  },
  beforeUnmount () {

  },
  methods: {
    connect () {
      this.socket = new WebSocket('wss://studio.ropiklabs.com:12700')
      this.socket.onopen = () => {
        this.sendMessage()
        this.socket.onmessage = ({ data }) => {
          console.log('receive data...' + data)
          // age,39,M,cloth,long sleeve top
          var catchDataList = data.split(',')
          console.log(catchDataList)
          console.log(catchDataList[1])

          if (catchDataList.length > 2) {
            console.log('is get data?')
            if (catchDataList[1] > 50) {
              console.log('in over 50')
              $('#subject_text').css('font-size', '40px')
              $('#studio-page-id').css('background', "url('/media/andongback2.webp') no-repeat")
              $('#lang_btn_list').css('width', '200px')
              $('#lang_btn_list').css('font-size', '50px')
            } else if (catchDataList[1] < 14) {
              $('#studio-page-id').css('background', "url('/media/andongback3.webp') no-repeat")
            } else {
              console.log('stable state')
            }
          }

          // this.logs.push({ event: "메세지 수신", data });
        }
      }
    },
    disconnect () {
      this.socket.close()
    },
    sendMessage (e) {
      console.log(e)
      this.socket.send('temp')
    },
    changeLang (lang) {
      this.lang = lang
      const rs = _axios.axiosSetChangeLanguage({ serial: this.serial, lang: lang })
      rs.then((response) => {
        console.log(response.data)
      })
    },
    micropone () {
      const ths = this
      // Set up the AudioContext.
      // const audioCtx = new AudioContext()

      // Top-level variable keeps track of whether we are recording or not.
      let recording = false
      console.log(navigator.mediaDevices)
      // Ask user for access to the microphone.
      if (navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          // Instantiate the media recorder.
          const mediaRecorder = new MediaRecorder(stream)

          // Create a buffer to store the incoming data.
          let chunks = []
          mediaRecorder.ondataavailable = (event) => {
            console.log('event', event)
            chunks.push(event.data)
          }

          // When you stop the recorder, create a empty audio clip.
          mediaRecorder.onstop = (event) => {
            const audio = new Audio()
            audio.setAttribute('controls', '')
            $('#sound-clip').append(audio)
            $('#sound-clip').append('<br />')

            // Combine the audio chunks into a blob, then point the empty audio clip to that blob.
            const blob = new Blob(chunks, { type: 'audio/webm; codecs=opus' })
            audio.src = window.URL.createObjectURL(blob)
            const formData = new FormData()
            formData.append('file', blob, 'audio.webm')
            formData.append('projectId', ths.projectId)
            formData.append('lang', ths.lang)
            formData.append('serial', ths.serial)
            const rs = _axios.axiosGetSpeechToText(formData)
            rs.then((response) => {
              console.log(response.data)
              if (response?.data?.error === '0001') {
                return false
              }
            })

            // Clear the `chunks` buffer so that you can record again.
            chunks = []
          }

          $('#record').on('mousedown', () => {
            mediaRecorder.start()
            recording = true
            ths.recording = true
          })
          $('html').on('mouseup', () => {
            if (recording) {
              mediaRecorder.stop()
              recording = false
              ths.recording = false
            }
          })
          $('#record').on('touchstart', () => {
            mediaRecorder.start()
            recording = true
            ths.recording = true
          })
          $('html').on('touchend', () => {
            if (recording) {
              mediaRecorder.stop()
              recording = false
              ths.recording = false
            }
          })
        }).catch((err) => {
          console.log(err)
          // Throw alert when the browser is unable to access the microphone.
          alert("Oh no! Your browser cannot access your computer's microphone.")
        })
      } else {
        // Throw alert when the browser cannot access any media devices.
        alert("Oh no! Your browser cannot access your computer's microphone. Please update your browser.")
      }
    },
    async getProjectId () {
      const serial = await localStorage.getItem('serial')
      if (serial === null) {
        this.$router.replace('/')
      } else {
        this.serial = serial
        const rs = await _axios.axiosGetProjectId(this.serial)
        if (rs.data.length > 0) {
          this.projectId = rs.data[0].project
        }
      }
    },
    async setMotionCheck (motion) {
      const params = {
        serial: this.serial,
        motion: motion,
        projectId: this.projectId
      }
      const rs = await _axios.axiosSetMotionCheck(params)
      if (rs.data.data) {
        this.selectContents(rs.data.data)
      }
    },
    async callHelper () {
      const rs = await _axios.axiosPostCallHelper()
      rs.then((response) => {
        console.log(response)
      })
    },
    async callEmergency () {
      const rs = await _axios.axiosPostCallEmergency()
      rs.then((response) => {
        console.log(response)
      })
    },
    async motionInit () {
      const ths = this

      ths.aiModel = await tmPose.load(ths.settings.modelURL, ths.settings.metadataURL)
      ths.maxPredictions = ths.aiModel.getTotalClasses()

      const size = 300
      const flip = true
      ths.webcam = new tmPose.Webcam(size, size, flip)
      await ths.webcam.setup()
      await ths.webcam.play()
      requestAnimationFrame(ths.loop)

      const canvas = document.getElementById('canvas')
      canvas.width = size
      canvas.height = size
      canvas.opacity = 0
      ths.ctx = canvas.getContext('2d')
      ths.labelContainer = document.getElementById('label-container')
      for (let i = 0; i < ths.maxPredictions; i++) {
        ths.labelContainer.appendChild(document.createElement('div'))
      }
    },

    // 모션감지 키스톤
    async loop () {
      const ths = this
      ths.webcam.update()
      await ths.predict()
      requestAnimationFrame(ths.loop)
    },

    // 감지 로직
    async predict () {
      const ths = this
      const { pose, posenetOutput } = await ths.aiModel.estimatePose(ths.webcam.canvas)
      const prediction = await ths.aiModel.predict(posenetOutput)

      // var swipeDiv = document.querySelector('#idleSwiper')
      // swipeDiv.style = { hidden: false }

      if (ths.isAnimated) {
        if (!pose && ths.inCheck && !ths.isClear) {
          ths.inCheck = false
          ths.isClear = true
          ths.clearAnimation()
          ths.setMotionCheck('pose_out')
          this.show_suggest = false
          setTimeout(() => {
            this.show = true
          }, 6000)
        }
      } else if (!pose && ths.inCheck) {
        ths.inCheck = false
        ths.isClear = true
        ths.clearAnimation()
        ths.setMotionCheck('pose_out')
        this.show_suggest = false
        setTimeout(() => {
          this.show = true
        }, 6000)

        // swipeDiv.style = { hidden: false }
      } else if (pose && !ths.inCheck) { // 입장시
        for (let i = 0; i < ths.maxPredictions; i++) {
          if (prediction[i].className === 'stable' && prediction[i].probability.toFixed(2) === '1.00') {
            ths.inCheck = true
            // ths.setMotionCheck('pose_in' + Math.floor(Math.random() * 5))
            ths.setMotionCheck('pose_in0')
            ths.connect()
            // swipeDiv.style = { hidden: true }
            this.show = false
            this.show_suggest = false
          }
        }
      } else if (pose && ths.inCheck) {
        // 캠에서 잡힌 객체 파싱
        for (let i = 0; i < ths.maxPredictions; i++) {
          // 처리결과
          // const classPrediction = prediction[i].className + ': ' + prediction[i].probability.toFixed(2)
          // greet인경우 정확도가 1.00일경우
          // console.log(prediction[i].className)
          // if (prediction[i].className === 'greet' && prediction[i].probability.toFixed(2) === '1.00') {
          //   ths.setMotionCheck(prediction[i].className)
          // } else if (prediction[i].className === 'not attention' && prediction[i].probability.toFixed(2) === '1.00') {
          //   ths.setMotionCheck('not_attention')
          // } else if (prediction[i].className === 'in and out' && prediction[i].probability.toFixed(2) === '1.00') {
          //   ths.setMotionCheck(prediction[i].className)
          // } else if (prediction[i].className === 'clap' && prediction[i].probability.toFixed(2) === '1.00') {
          //   ths.setMotionCheck(prediction[i].className)
          // } else if (prediction[i].className === 'handup' && prediction[i].probability.toFixed(2) === '1.00') {
          //   ths.setMotionCheck(prediction[i].className)
          // } else
          // if (prediction[i].className === 'oldman' && prediction[i].probability.toFixed(2) === '1.00') {
          //   ths.setMotionCheck(prediction[i].className)
          //   ths.callHelper()
          // } else if (prediction[i].className === 'disabledman' && prediction[i].probability.toFixed(2) === '1.00') {
          //   ths.setMotionCheck(prediction[i].className)
          //   ths.callHelper()
          // } else if (prediction[i].className === 'emergency' && prediction[i].probability.toFixed(2) === '1.00') {
          //   ths.setMotionCheck(prediction[i].className)
          //   ths.callEmergency()
          // } else {
          // ths.issCount++
          // if (ths.issCount % 900 === 0 && ths.isAnimated !== true) {
          //   console.log('something wrong')
          //   console.log('isss...', ths.issCount)
          // }
          // }
          // 화면출력
          // ths.labelContainer.childNodes[i].innerHTML = prediction[i].className + ': ' + prediction[i].probability.toFixed(2)
        }
      } else {
        console.log('아무것도 아닌경우.')
      }

      // 출력된 이미지에 스켈레톤(모션 키포인트) 그려줌
      ths.drawPose(pose)
    },

    drawPose (pose) {
      const ths = this
      if (ths.webcam.canvas) {
        ths.ctx.drawImage(ths.webcam.canvas, 0, 0)
        if (pose) {
          const minPartConfidence = 0.5
          tmPose.drawKeypoints(pose.keypoints, minPartConfidence, ths.ctx)
          tmPose.drawSkeleton(pose.keypoints, minPartConfidence, ths.ctx)
        }
      }
    },
    async studioInit () {
      try {
        await this.getProjectId()
        let rs = await _axios.axiosGetContentsList(this.projectId)
        for (let i = 0; i < rs.data.length; i++) {
          const data = JSON.parse(rs.data[i].data)
          this.contentsList.push(data)
        }

        rs = await _axios.axiosGetFileGroupList(this.projectId)
        const fileIdList = []
        for (let i = 0; i < rs.data.length; i++) {
          const compFileGroup = {
            projectId: rs.data[i].project,
            contentsId: rs.data[i].contents_id,
            componentId: rs.data[i].component_id,
            fileId: rs.data[i].file,
            sort: rs.data[i].sort,
            state: 'S'
          }
          this.fileGroupList.push(compFileGroup)
          if (fileIdList.filter(item => item === rs.data[i].file).length === 0) {
            fileIdList.push(rs.data[i].file)
          }
        }

        for (let i = 0; i < fileIdList.length; i++) {
          const rs2 = await _axios.axiosGetFile(fileIdList[i])
          const item = rs2.data
          const fileData = {
            fileId: item.file_id,
            name: item.name,
            size: item.size,
            file: null,
            type: item.type,
            target: item.target,
            tmpUrl: '',
            url: item.url,
            state: 'S'
          }
          this.fileList.push(fileData)
        }

        rs = await _axios.axiosGetComponentList(this.projectId)
        for (let i = 0; i < rs.data.length; i++) {
          const data = JSON.parse(rs.data[i].data)
          this.components.push(data)
        }

        rs = await _axios.axiosGetAnimationList(this.projectId)
        for (let i = 0; i < rs.data.length; i++) {
          const data = JSON.parse(rs.data[i].data)
          this.animationList.push(data)
        }
        // this.selectContents(this.contentsList[0].contentsId)
      } catch (e) {
        console.log(e.response)
      }
    },
    selectContents (contentsId) {
      console.log('contentsId', contentsId)
      this.selectedContents = this.contentsList.filter(item => item.contentsId === contentsId)[0] || null
      console.log('this.selectedContents', this.selectedContents)
      if (this.selectedContents !== null) {
        this.selectedComponentList = _.cloneDeep(this.components.filter(item => item.contentsId === contentsId))
        if (this.components.filter(item => item.contentsId === contentsId && item.type === 'people').length === 0) {
          this.showCharacter = false
        }
        console.log('selectedContents not null ', contentsId)
        this.startAnimations(contentsId)
      }
    },
    startAnimations (contentsId) {
      this.cnt = 0
      this.selectedAnimationList = _.cloneDeep(this.animationList.filter(item => item.contentsId === contentsId))
      const animations = this.selectedAnimationList.filter(item => item.timing.action !== 'ended')
      console.log('632... animations', animations)
      for (let i = 0; i < animations.length; i++) {
        this.doAnimation(animations[i])
      }
    },
    doDelayAnimation () {

    },
    clearAnimation () {
      const ths = this
      if (ths.interval) {
        clearInterval(ths.interval)
        ths.interval = null
      }
      if (ths.faceInterval) {
        clearInterval(ths.faceInterval)
        ths.faceInterval = null
      }
      if (ths.timeout.main) {
        clearTimeout(ths.timeout.main)
      }
      if (ths.timeout.quiz) {
        clearTimeout(ths.timeout.quiz)
      }
      if (ths.timeout.text) {
        clearTimeout(ths.timeout.text)
      }
      ths.selectedContents = null
      ths.selectedAnimationList = []
      ths.selectedComponentList = []
      ths.hiddenCatchCircles = []
      ths.hiddenCatch = []
      ths.isComplete = false
      ths.isAnimated = false
      ths.isVideoPlay = false

      if ($('#output')[0]) {
        $('#output')[0].pause()
      }
      // if($('#quizOutput')[0]){
      //   $('#quizOutput')[0].pause()
      // }
      // if($('#textOutput')[0]){
      //   $('#textOutput')[0].pause()
      // }

      ths.audioShow = false
      $('#character').stop().animate({ top: 0, left: 0, opacity: 1 })
      // ths.audioShow = true
      // ths.faceCount = 0
      // ths.qurator.face.morphTargetInfluences[1] = ths.faceCount
    },
    endAnimation (animation) {
      animation.isPlay = 'end'
      if (this.selectedAnimationList.filter(itm => itm.isPlay !== 'end').length === 0) {
        this.clearAnimation()
      }
    },
    playCharacterAnimation (animation, endedAnimations) {
      const ths = this
      $('#character').animate({
        opacity: animation.status.opacity,
        left: (animation.status.left * ths.settings.ratio - ths.characterCss.left),
        top: (animation.status.top * ths.settings.ratio - ths.characterCss.top)
      }, animation.duration, () => {
        if (animation.status.emote) {
          ths.fadeToAction(animation.status.emote, 0.2)
          ths.qurator.mixer.addEventListener('finished', () => {
            ths.qurator.mixer.removeEventListener('finished')
            ths.fadeToAction(ths.qurator.api.state, 0.2)
            ths.endAnimation(animation)
            const anims = endedAnimations.filter(itm => itm.isPlay !== 'ing' && itm.isPlay !== 'end')
            for (let i = 0; i < anims.length; i++) {
              ths.doAnimation(anims[i])
            }
          })
        } else {
          ths.endAnimation(animation)
          const anims = endedAnimations.filter(itm => itm.isPlay !== 'ing' && itm.isPlay !== 'end')
          for (let i = 0; i < anims.length; i++) {
            ths.doAnimation(anims[i])
          }
        }
      })
    },
    playQuizAnimation (componentId, targetComponents, animation, endedAnimations) {
      const ths = this
      $('[component_id=' + componentId + ']').animate({
        opacity: 1
      }, animation.duration, function () {
        if (targetComponents.length > 0) {
          const params = {
            projectId: ths.projectId,
            serial: ths.serial,
            quiz: 1,
            lang: ths.lang,
            text: targetComponents[0].question
          }
          _axios.axiosSetQuiz(params)
          const rs = _axios.axiosGetTextToSpeech(params)
          rs.then((response) => {
            if (response.data.code === '0000') {
              ths.translatedText = response.data.data
              ths.playQuizAudio('/media/output.mp3?r=' + Math.random(), animation, endedAnimations)
            }
          })
        }
      })
    },
    playHiddenCatchAnimation (componentId, targetComponents, animation, endedAnimations) {
      const ths = this
      $('[component_id=' + componentId + ']').animate({
        opacity: 1
      }, animation.duration, function () {
        if (targetComponents.length > 0) {
          const fileData = ths.getFile(componentId)
          const params = {
            projectId: ths.projectId,
            serial: ths.serial,
            hiddenCatch: [...fileData],
            count: targetComponents[0].circles.length
          }
          _axios.axiosSetHiddenCatch(params)
        }
      })
    },
    playTextAnimation (targetComponents, animation, endedAnimations) {
      const ths = this
      if (targetComponents.length > 0) {
        const params = {
          projectId: ths.projectId,
          serial: ths.serial,
          lang: ths.lang,
          text: targetComponents[0].text
        }
        const rs = _axios.axiosGetTextToSpeech(params)
        rs.then((response) => {
          if (response.data.code === '0000') {
            ths.translatedText = response.data.data
            ths.playTextAudio('/media/output.mp3?r=' + Math.random(), animation, endedAnimations)
          }
        })
      }
    },
    playVideoAnimation (componentId, animation, endedAnimations) {
      const ths = this
      $('[component_id=' + componentId + ']').animate({
        opacity: animation.status.opacity,
        left: animation.status.left * ths.settings.ratio,
        top: (animation.status.top) * ths.settings.ratio,
        width: animation.status.width * ths.settings.ratio,
        height: animation.status.height * ths.settings.ratio
      }, animation.duration, function () {
        console.log('doing animation component...', animation.targetComponent)
        if (animation.targetComponent.type === 'video' && animation.targetComponent.play === 'play') {
          const vid = $(this).find('video')[0]
          vid.play()
          vid.onended = (e) => {
            e.preventDefault()
            ths.endAnimation(animation)
            const anims = endedAnimations.filter(itm => itm.isPlay !== 'ing' && itm.isPlay !== 'end')
            for (let i = 0; i < anims.length; i++) {
              ths.doAnimation(anims[i])
            }
          }
        } else {
          ths.endAnimation(animation)
        }
      })
    },
    doAnimation (animation) {
      const ths = this
      const componentId = animation.targetComponent.componentId
      const targetComponents = ths.components.filter(itm => itm.componentId === componentId)
      const endedAnimations = this.selectedAnimationList.filter(itm => itm.timing.target === animation.animationId && itm.timing.action === 'ended' && itm.isPlay !== 'ing' && itm.isPlay !== 'end')
      animation.isPlay = 'ing'
      ths.isAnimated = true

      // console.log('animation ... ', animation)
      // console.log('compnents ... ', ths.components)
      console.log('component id ... ', componentId)

      // content id 가 35일때 인트로가 종료됨
      // 이 시점에 show_suggest_text출력하고
      // mic 입력을 open한다.
      // mic는 약 5초간 열리고 5초뒤 닫는다.
      // 닫은 파일은 정리 후 텍스트로 변환하며, 변환된 텍스트에 따라 컨텐츠를 실행한다.
      // gpt추가로 위 내용은 안동실증시에만 사용, 이외에는 gpt를 활용한다.

      if (componentId === 35 || componentId === 450 || componentId === 459 || componentId === 462 || componentId === 466 || componentId === 470 || componentId === 474 || componentId === 477 || componentId === 480 || componentId === 483 || componentId === 486) {
        ths.checkFirst = false
        ths.isAnimated = false
        if (navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            // Instantiate the media recorder.
            const mediaRecorder = new MediaRecorder(stream)

            // Create a buffer to store the incoming data.
            let chunks = []
            mediaRecorder.ondataavailable = (event) => {
              console.log('event', event)
              chunks.push(event.data)
            }

            // When you stop the recorder, create a empty audio clip.
            mediaRecorder.onstop = (event) => {
              if (ths.show) {
                // 사람이 이탈한 경우 데이터 전송하지않음.
                console.log('mic end but user not found')
                return
              }

              const audio = new Audio()
              audio.setAttribute('controls', '')
              $('#sound-clip').append(audio)
              $('#sound-clip').append('<br />')

              // Combine the audio chunks into a blob, then point the empty audio clip to that blob.
              const blob = new Blob(chunks, { type: 'audio/webm; codecs=opus' })
              audio.src = window.URL.createObjectURL(blob)
              const formData = new FormData()
              formData.append('file', blob, 'audio.webm')
              formData.append('projectId', ths.projectId)
              formData.append('lang', ths.lang)
              formData.append('serial', ths.serial)
              const rs = _axios.axiosGetSpeechToText(formData)
              rs.then((response) => {
                console.log('form', formData)
                console.log('response', response)
                console.log('reponsedata', response.data)

                // 무한반복이 된다.
                // 끝나는 시점이 알맞지않음... inputext길이를 20자이내로 맞추어서 시간을 강제로 픽스 시켜야 함.
                if (response.data.result === 'success') {
                  setTimeout(() => {
                    const tempAnime = {
                      targetComponent: { componentId: 35, play: 'play', type: 'text' },
                      projectId: '6'
                    }

                    if (ths.show !== true && ths.isAnimated !== true) {
                      this.doAnimation(tempAnime)
                    }
                  }, 15000)
                } else if (response?.data?.error === '0001') {
                  // 마이크 입력시 아무말 하지않으면 ... 0001이 뜬다.
                  // 이때 처리

                  // ths.isClear true면 그냥 지나감
                  const tempAnime = {
                    targetComponent: { componentId: 35, play: 'play', type: 'text' },
                    projectId: '6'

                  }
                  console.log('do ani2... ' + ths.show + '  / ' + ths.isAnimated)
                  console.log('do ani2... ' + ths.show !== true + '  / ' + ths.isAnimated !== true)
                  if (ths.show !== true) {
                    setTimeout(() => {
                      this.doAnimation(tempAnime)
                      console.log('.......')
                    }, 1000)
                  }

                  return false
                }
              })

              // Clear the `chunks` buffer so that you can record again.
              chunks = []
            }

            setTimeout(() => {
              console.log('show suggest')
              this.show_suggest = true
            }, 8000)

            setTimeout(() => {
              console.log('recoding mic')
              mediaRecorder.start()
              $('#record').css({ opacity: 1 })
            }, 10000)

            setTimeout(() => {
              console.log('end recoding')
              mediaRecorder.stop()
              this.show_suggest = false
              $('#record').css({ opacity: 0 })
            }, 16000)
          }).catch((err) => {
            console.log(err)
          })
        } else {
          console.log('no mic')
        }
      }

      if (ths.isClear === true) {
        ths.clearAnimation()
      } else {
        ths.timeout.main = setTimeout(() => {
          console.log('timeout main...')
          console.log('anime type ... ', animation.targetComponent.type)
          if (animation.targetComponent.type === 'people') {
            ths.playCharacterAnimation(animation, endedAnimations)
          } else if (animation.targetComponent.type === 'quiz') {
            ths.playQuizAnimation(componentId, targetComponents, animation, endedAnimations)
          } else if (animation.targetComponent.type === 'hiddenCatch') {
            ths.playHiddenCatchAnimation(componentId, targetComponents, animation, endedAnimations)
          } else if (animation.targetComponent.type === 'text') {
            ths.playTextAnimation(targetComponents, animation, endedAnimations)
          } else {
            ths.playVideoAnimation(componentId, animation, endedAnimations)
          }
        }, animation.prevDelay)
      }
    },
    checkAnswer (animation, endedAnimations) {
      const ths = this
      if (this.reciveAnswer !== '') {
        $('.quiz_answer').fadeIn('slow')
        setTimeout(() => {
          $('.quiz-component').animate({ opacity: 0 }, 1000)
          $('.quiz_answer').fadeOut('slow')
          this.reciveAnswer = ''
          ths.endAnimation(animation)
          const anims = endedAnimations.filter(itm => itm.isPlay !== 'ing' && itm.isPlay !== 'end')
          for (let i = 0; i < anims.length; i++) {
            ths.doAnimation(anims[i])
          }
        }, 5000)
      } else {
        setTimeout(() => {
          this.checkAnswer(animation, endedAnimations)
        }, 1000)
      }
    },
    getCharacterStyle () {
      let data = { top: 0, left: 0, opacity: 1 }
      if (this.selectedContents !== null) {
        const datas = this.components.filter(item => item.contentsId === this.selectedContents.contentsId && item.type === 'people')
        if (datas.length > 0) {
          data = {
            left: (datas[0].left * this.settings.ratio - this.characterCss.left) + 'px',
            top: (datas[0].top * this.settings.ratio - this.characterCss.top) + 'px',
            opacity: datas[0].opacity
          }
        } else {
          data = {
            top: 0,
            left: 0,
            opacity: 1,
            display: 'none'
          }
        }
      }
      return data
    },
    getQuizStyle (item) {
      return {
        opacity: 0,
        left: '25%',
        top: '15%',
        width: '70%',
        position: 'absolute'
      }
    },
    getHiddenCatchCirCleStyle (v, item) {
      return {
        position: 'absolute',
        left: (((item.width / 500) * v.left - 20 * (item.width / 500)) / 2) * this.settings.ratio + 'px',
        top: ((item.height / 600) * v.top - 20 * (item.height / 600)) * this.settings.ratio + 'px',
        width: 40 * (item.width / 500) / 2 * this.settings.ratio + 'px',
        height: 40 * (item.width / 500) / 2 * this.settings.ratio + 'px',
        // border: '3px solid red',
        // borderRadius : '1000px',
        zIndex: 10
      }
    },
    getHiddenCatchStyle (item) {
      return {
        zIndex: item.zIndex,
        left: (item.left) * this.settings.ratio + 'px',
        top: (item.top) * this.settings.ratio + 'px',
        width: (item.width * this.settings.ratio) + 'px',
        height: (item.height * this.settings.ratio) + 'px',
        position: 'absolute'
      }
    },
    getStyle (item) {
      return {
        zIndex: item.zIndex,
        left: item.left * this.settings.ratio + 'px',
        top: item.top * this.settings.ratio + 'px',
        width: item.width * this.settings.ratio + 'px',
        height: item.height * this.settings.ratio + 'px',
        position: 'absolute'
      }
    },
    getFile (componentId) {
      const fileList = []
      const fileGroupList = this.fileGroupList.filter(itm => itm.componentId === componentId)
      for (let i = 0; i < fileGroupList.length; i++) {
        for (let j = 0; j < this.fileList.length; j++) {
          if (fileGroupList[i].fileId === this.fileList[j].fileId) {
            fileList.push(this.fileList[j])
          }
        }
      }
      return fileList
    },
    getSrc (item, index) {
      let url = ''
      const seq = index || 0
      if (item.type === 'video' || item.type === 'audio' || item.type === 'image') {
        const fileData = this.getFile(item.componentId)
        if (fileData.length === 0) {
          return ''
        }

        url = fileData[0].url
        url = url.replace(this.settings.replaceUrl, '')
      }
      if (item.type === 'hiddenCatch') {
        const fileData = this.getFile(item.componentId)
        if (fileData.length === 0) {
          return ''
        }

        url = fileData[seq].url
        url = url.replace(this.settings.replaceUrl, '')
      }
      // if (item.type === 'people') {
      //   url = this.characterList[this.modalData.character].src
      // }
      // console.log(url)
      return url
    },
    async firebaseInit () {
      const ths = this
      // Import the functions you need from the SDKs you need
      // TODO: Add SDKs for Firebase products that you want to use
      // https://firebase.google.com/docs/web/setup#available-libraries

      // Your web app's Firebase configuration
      // For Firebase JS SDK v7.20.0 and later, measurementId is optional

      // $('#output').unbind('ended').on('ended', function () {
      //   $('#output')[0].currentTime = 0
      //   ths.selectedContents = null
      //   // ths.selectedComponentList = []
      //   if (ths.faceInterval) {
      //     clearInterval(ths.faceInterval)
      //   }
      //   // ths.fadeToAction(ths.qurator.api.state, 0.2)
      //   ths.faceCount = 0
      //   ths.qurator.face.morphTargetInfluences[1] = ths.faceCount
      //   ths.countArrow = true
      //   ths.isVideoPlay = false
      //   setTimeout(() => {
      //     ths.isAnimated = false
      //     ths.translatedText = ''
      //   }, 2000)
      // })

      // Initialize Firebase
      this.app = initializeApp(FIREBASECONFIG)
      const db = getDatabase(this.app)
      onValue(ref(db, 'lang/' + this.serial), (snapshot) => {
        if (snapshot.val()) {
          ths.lang = snapshot.val()
        }
      })
      onValue(ref(db, 'answer/' + this.serial), (snapshot) => {
        if (ths.isFirst) {
          ths.isFirst = false
        } else {
          if (snapshot.val()) {
            ths.reciveAnswer = JSON.parse(snapshot.val()).answer
            $('.quiz-component').each((index, value) => {
              if ($(value).css('opacity') === '1') {
                if (ths.reciveAnswer === $(value).find('.quiz_ox').text()) {
                  setTimeout(() => {
                    ths.playAction('clap', 0.2)
                  }, 1000)
                } else {
                  setTimeout(() => {
                    ths.playAction('disapoint', 0.2)
                  }, 1000)
                }
              }
            })
          }
        }
      })
      onValue(ref(db, 'hidden_catch_select/' + this.serial), (snapshot) => {
        if (ths.isHiddenCatchSelectFirst) {
          ths.isHiddenCatchSelectFirst = false
        } else {
          if (snapshot.val()) {
            const data = JSON.parse(snapshot.val()).hidden_catch_select
            const item = {
              left: data.left * 5 / 7,
              top: data.top * 5 / 7
            }
            ths.hiddenCatchCircles.push(item)
          }
        }
      })
      onValue(ref(db, 'hidden_catch_complete/' + this.serial), (snapshot) => {
        if (ths.isHiddenCatchCompleteFirst) {
          ths.isHiddenCatchCompleteFirst = false
        } else {
          if (snapshot.val()) {
            ths.reciveHiddenCatchComplete = JSON.parse(snapshot.val()).hidden_catch_complete
            ths.isComplete = true
            const targetComponents = ths.selectedComponentList.filter(item => item.type === 'hiddenCatch')
            const completes = ths.hiddenCatchCircles.filter(item => targetComponents[0].circles.filter(itm => Math.abs(itm.left - item.left) <= 20 && Math.abs(itm.top - item.top) <= 20).length > 0)
            ths.translatedText = '총 ' + targetComponents[0].circles.length + '개 중 ' + completes.length + '개 맞추셨습니다.'
            setTimeout(() => {
              ths.translatedText = ''
              ths.clearAnimation()
            }, 3000)
            // $('.quiz-component').each((index, value) => {
            //   if ($(value).css('opacity') === '1') {
            //     if (ths.reciveAnswer === $(value).find('.quiz_ox').text()) {
            //       setTimeout(() => {
            //         ths.playAction('clap', 0.2)
            //       }, 1000)
            //     } else {
            //       setTimeout(() => {
            //         ths.playAction('disapoint', 0.2)
            //       }, 1000)
            //     }
            //   }
            // })
          }
        }
      })
      onValue(ref(db, 'event/' + this.serial), (snapshot) => {
        console.log('here', this.serial)
        console.log('here', snapshot)
        console.log('here', ths.checkFirst)
        console.log('here', ths.isAnimated)
        // ths.isAnimated, checkFirst 이 두개 때문에 여기서 뭔가 안됨
        if (ths.checkFirst || ths.isAnimated) {
          ths.checkFirst = false
          console.log('checkfirst true')
        } else if (snapshot.val()) {
          console.log('checkfirst, isAnimated true')
          const data = JSON.parse(snapshot.val())
          if (data.type === 'audio' && data.text.length > 0) {
            console.log('data type audio')
            ths.translatedText = data.text
            ths.playAudio('/media/output.mp3?r=' + Math.random())
          } else if (data.type === 'contents') {
            console.log('data type else')
            ths.selectContents(data.contentsId)
          }
        }
      })
    },
    playQuizAudio (src, animation, endedAnimations) {
      const ths = this
      const aud = $('#output')[0]
      aud.setAttribute('src', src)
      aud.addEventListener('loadeddata', (event) => {
        event.preventDefault()
        aud.play()
        ths.faceIntervalStart()
        aud.onended = (e) => {
          e.preventDefault()
          aud.removeEventListener('loadeddata', this)
          ths.faceIntervalEnd()
          ths.checkAnswer(animation, endedAnimations)
          setTimeout(() => {
            ths.translatedText = ''
          }, 2000)
        }
      })
    },
    playTextAudio (src, animation, endedAnimations) {
      const ths = this
      const aud = $('#output')[0]
      aud.setAttribute('src', src)
      aud.addEventListener('loadeddata', (event) => {
        event.preventDefault()
        aud.play()
        ths.faceIntervalStart()
        aud.onended = (e) => {
          e.preventDefault()
          aud.removeEventListener('loadeddata', this)
          ths.faceIntervalEnd()
          ths.endAnimation(animation)
          for (let i = 0; i < endedAnimations.length; i++) {
            ths.doAnimation(endedAnimations[i])
          }
          setTimeout(() => {
            ths.translatedText = ''
          }, 2000)
        }
      })
    },
    playAudio (src) {
      const ths = this
      const aud = $('#output')[0]
      aud.setAttribute('src', src)
      aud.addEventListener('loadeddata', (event) => {
        event.preventDefault()
        aud.play()
        ths.faceIntervalStart()
        aud.onended = (e) => {
          e.preventDefault()
          aud.removeEventListener('loadeddata', this)
          ths.faceIntervalEnd()
          setTimeout(() => {
            ths.translatedText = ''
          }, 2000)
        }
      })
    },
    faceIntervalEnd () {
      const ths = this
      if (ths.faceInterval) {
        clearInterval(ths.faceInterval)
        ths.faceInterval = null
      }
      // ths.faceCount = 0
      // ths.qurator.face.morphTargetInfluences[1] = ths.faceCount
      ths.countArrow = true
    },
    faceIntervalStart () {
      const ths = this
      if (ths.faceInterval) {
        clearInterval(ths.faceInterval)
      }
      // ths.faceInterval = setInterval(() => {
      //   if (ths.countArrow === true) {
      //     ths.faceCount = ths.faceCount + 0.04
      //     if (ths.faceCount >= 1) {
      //       ths.countArrow = false
      //     }
      //   } else {
      //     ths.faceCount = ths.faceCount - 0.04
      //     if (ths.faceCount <= 0) {
      //       ths.countArrow = true
      //     }
      //   }
      //   ths.qurator.face.morphTargetInfluences[1] = ths.faceCount
      // }, 10)
    },
    init () {
      const ths = this
      const container = document.getElementById('character')
      // document.body.appendChild(this.container)

      this.qurator.camera = new Three.PerspectiveCamera(45, 1600 / 900, 0.25, 100)
      this.qurator.camera.position.set(0, 1, 3.5)
      this.qurator.camera.lookAt(new Three.Vector3(0, 0, -50))

      this.qurator.scene = new Three.Scene()
      this.qurator.scene.fog = new Three.Fog(0xe0e0e0, 20, 100)
      // this.qurator.scene.rotateX(0)
      this.qurator.clock = new Three.Clock()

      // lights

      const hemiLight = new Three.HemisphereLight(0xffffff, 0x444444)
      hemiLight.position.set(0, 20, 0)
      this.qurator.scene.add(hemiLight)

      const dirLight = new Three.DirectionalLight(0xffffff)
      dirLight.position.set(0, 20, 10)
      this.qurator.scene.add(dirLight)

      // model

      const loader = new GLTFLoader()
      loader.load('/media/models/' + ths.projectCharacter.model, function (gltf) {
        const model = gltf.scene
        ths.qurator.scene.add(model)

        ths.createGUI(model, gltf.animations)
      }, undefined, function (e) {
        console.error(e)
      })

      this.qurator.renderer = new Three.WebGLRenderer({ antialias: true, alpha: true })
      this.qurator.renderer.setPixelRatio(window.devicePixelRatio)
      this.qurator.renderer.setSize(1600, 900)
      this.qurator.renderer.outputEncoding = Three.sRGBEncoding
      container.appendChild(this.qurator.renderer.domElement)
    },
    createGUI (model, animations) {
      const ths = this

      const states = ths.states
      const emotes = ths.emotes

      const gui = new GUI()

      const cameraFolder = gui.addFolder('Camera')

      cameraFolder.add(this.qurator.camera.position, 'x', -50, 50).step(1)
      cameraFolder.add(this.qurator.camera.position, 'y', -50, 50).step(1)
      cameraFolder.add(this.qurator.camera.position, 'z', 0, 50).step(1)
      cameraFolder.add(this.qurator.scene.rotation, 'z', -360 * Math.PI / 180, 360 * Math.PI / 180).step(0.1)

      cameraFolder.open()

      this.qurator.mixer = new Three.AnimationMixer(model)
      this.qurator.mixer.timeScale = 0.5
      this.qurator.actions = {}

      for (let i = 0; i < animations.length; i++) {
        const clip = animations[i]
        const action = this.qurator.mixer.clipAction(clip)
        this.qurator.actions[clip.name] = action

        if (emotes.indexOf(clip.name) >= 0 || states.indexOf(clip.name) >= 4) {
          action.clampWhenFinished = true
          action.loop = Three.LoopOnce
        }
      }

      const statesFolder = gui.addFolder('States')

      const clipCtrl = statesFolder.add(this.qurator.api, 'state').options(states)

      clipCtrl.onChange(function () {
        ths.fadeToAction(ths.qurator.api.state, 0.5)
      })

      statesFolder.open()

      const emoteFolder = gui.addFolder('Emotes')

      function createEmoteCallback (name) {
        ths.qurator.api[name] = function () {
          ths.fadeToAction(name, 0.2)

          ths.qurator.mixer.addEventListener('finished', restoreState)
        }

        emoteFolder.add(ths.qurator.api, name)
      }

      function restoreState () {
        ths.qurator.mixer.removeEventListener('finished', restoreState)

        ths.fadeToAction(ths.qurator.api.state, 0.2)
      }

      for (let i = 0; i < emotes.length; i++) {
        createEmoteCallback(emotes[i])
      }

      emoteFolder.open()

      // this.face = model.getObjectByName('STEVE_BODY')
      // console.log(this.projectCharacter.body)
      this.qurator.face = model.getObjectByName('GEO_Head004')
      // console.log(model)
      // const expressions = Object.keys(this.face.morphTargetDictionary)
      // const expressionFolder = this.gui.addFolder('Expressions')

      // for (let i = 0; i < expressions.length; i++) {
      //   expressionFolder.add(this.face.morphTargetInfluences, i, 0, 1, 0.01).name(expressions[i])
      // }

      this.qurator.activeAction = this.qurator.actions.idle
      this.qurator.activeAction.play()

      // expressionFolder.open()
    },

    playAction (name, duration) {
      const ths = this
      ths.fadeToAction(name, duration)
      ths.qurator.mixer.addEventListener('finished', () => {
        ths.qurator.mixer.removeEventListener('finished')
        ths.fadeToAction(ths.qurator.api.state, 0.2)
      })
    },

    fadeToAction (name, duration) {
      this.qurator.previousAction = this.qurator.activeAction
      this.qurator.activeAction = this.qurator.actions[name]

      if (this.qurator.previousAction !== this.qurator.activeAction) {
        this.qurator.previousAction.fadeOut(duration)
      }

      this.qurator.activeAction
        .reset()
        .setEffectiveTimeScale(1)
        .setEffectiveWeight(1)
        .fadeIn(duration)
        .play()
    },

    // onWindowResize () {
    //   this.qurator.camera.aspect = window.innerWidth / window.innerHeight
    //   this.qurator.camera.updateProjectionMatrix()

    //   this.qurator.renderer.setSize(window.innerWidth, window.innerHeight)
    // },

    animate () {
      const dt = this.qurator.clock.getDelta()

      if (this.qurator.mixer) this.qurator.mixer.update(dt)

      requestAnimationFrame(this.animate)

      this.qurator.renderer.render(this.qurator.scene, this.qurator.camera)
    }
  }
}
</script>
